import React from 'react';
import { graphql } from 'gatsby';

import PageHero from 'src/components/content/PageHero';
import MarkdownSectionsNavigator from 'src/components/markdown/MarkdownSectionsNavigator';
import { useLocalization } from 'src/providers/LocalizationProvider';
import DataProvider from 'src/providers/DataProvider';

export default function FoundationPage({ data }) {
  const {
    allFile: { nodes: heroImages },
    craft: { countryData },
  } = data;

  const { page, t } = useLocalization();

  return (
    <DataProvider entries={{ countryData }}>
      <PageHero
        focusColor="brand.foundation"
        textColor="common.white"
        images={heroImages}
        text={t('hero.foundation')}
      />
      <MarkdownSectionsNavigator page={page} focusColor="brand.foundation" />
    </DataProvider>
  );
}

export const query = graphql`
  query FoundationPage($locale: String) {
    craft {
      # team: entries(site: [$locale], type: "team") {
      #   slug
      #   id
      #   ...teamData
      # }
      countryData: entries(
        section: "countryData"
        site: [$locale]
        orderBy: "countryName"
        status: "enabled"
      ) {
        sectionHandle
        ...countryData
      }
    }
    allFile(
      limit: 2
      filter: { relativePath: { regex: "/hero/foundation(.*)/i" } }
    ) {
      nodes {
        childImageSharp {
          fluid(maxHeight: 280, maxWidth: 380) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;
