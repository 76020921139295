import React from 'react';
import PT from 'prop-types';
import get from 'lodash/get';

import Image from 'gatsby-image';

import NoSsr from '@material-ui/core/NoSsr';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from 'cl-ui/components/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/styles';
import PageHeader from 'src/components/content/PageHeader';

const usePrintDesign = makeStyles(theme => ({
  root: ({ classes, focusColor, textColor }) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '490px',
    backgroundColor: focusColor,
    color: textColor,
    [theme.breakpoints.up('sm')]: {
      height: '890px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '770px',
    },
    ...(classes && classes.root),
  }),
  header: ({ classes }) => ({
    zIndex: 10,
    position: 'relative',
    ...(classes && classes.header),
  }),
  darkStuff: {
    position: 'absolute',
    width: '100%',
    height: '170px',
    zIndex: 1,
    bottom: 0,
    left: 0,
    backgroundColor: theme.palette.secondary.dark,
    [theme.breakpoints.up('sm')]: {
      height: '290px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '330px',
      bottom: 'auto',
      left: 'auto',
      top: 0,
      right: 0,
    },
  },
  container: ({ classes }) => ({
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 2,
    ...(classes && classes.container),
  }),
  innerContainer: {
    paddingBottom: theme.spacing(16),
  },
  text: ({ classes }) => ({
    [theme.breakpoints.down('xs')]: {
      fontSize: 26,
    },
    ...(classes && classes.text),
  }),
  image: ({ classes }) => ({
    height: '140px',
    transform: 'translateY(50px)',
    [theme.breakpoints.up('sm')]: {
      transform: 'translateY(100px)',
      height: '280px',
      width: 'auto',
      maxWidth: '380px',
    },
    [theme.breakpoints.up('lg')]: {
      transform: 'translateY(0)',
    },
    ...(classes && classes.image),
  }),
}));

const ImageSubGrid = ({ styles, leftImage, rightImage }) => (
  <Grid
    alignItems="center"
    alignContent="center"
    justify="center"
    container
    spacing={2}
  >
    <Grid item xs={8}>
      {rightImage && (
        <Image
          crititcal
          className={styles.image}
          objectFit="cover"
          objectPosition="right 50%"
          alt={rightImage.title}
          fluid={rightImage.childImageSharp.fluid}
        />
      )}
    </Grid>
    <Grid item xs={4}>
      {leftImage && (
        <Image
          crititcal
          className={styles.image}
          objectFit="cover"
          objectPosition="right 50%"
          alt={leftImage.title}
          fluid={leftImage.childImageSharp.fluid}
        />
      )}
    </Grid>
  </Grid>
);

const PageHero = props => {
  const {
    focusColor = 'primary',
    textColor = 'common.white',
    text,
    images = [],
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // looking up the passed key in the theme palette
  const focusColorValue = get(theme.palette, focusColor, theme.palette.primary);
  const textColorValue = get(theme.palette, textColor, theme.palette.primary);

  const [leftImage, rightImage] = images;

  const styles = usePrintDesign({
    ...props,
    isMobile,
    focusColor: focusColorValue,
    textColor: textColorValue,
  });

  return (
    <Box className={styles.root}>
      <div className={styles.darkStuff} />
      <Grid
        className={styles.container}
        container
        alignItems="center"
        alignContent="space-between"
        justify="center"
      >
        <PageHeader inverse={true} className={styles.header} />
        <Grid
          className={styles.innerContainer}
          container
          alignItems="center"
          alignContent="center"
          justify="center"
        >
          {isDesktop && (
            <Grid item xs={1} lg={2}>
              {leftImage && (
                <Image
                  crititcal
                  className={styles.image}
                  objectFit="cover"
                  objectPosition="left 50%"
                  alt={leftImage.title}
                  fluid={leftImage.childImageSharp.fluid}
                />
              )}
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <NoSsr>
              <Box px={[2, 4, 6, 6]}>
                <Typography className={styles.text} variant="h2">
                  {text}
                </Typography>
              </Box>
            </NoSsr>
          </Grid>
          {isDesktop && (
            <Grid item xs={1} lg={4}>
              <ImageSubGrid
                styles={styles}
                leftImage={leftImage}
                rightImage={rightImage}
              />
            </Grid>
          )}
          {/* we have to defraggle the grid here because of layout cannot be just simple */}
          {!isDesktop && (
            <Grid
              className={styles.imageOffset}
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={3}>
                {leftImage && (
                  <Image
                    crititcal
                    className={styles.image}
                    objectFit="cover"
                    objectPosition="left 50%"
                    alt={leftImage.title}
                    fluid={leftImage.childImageSharp.fluid}
                  />
                )}
              </Grid>
              <Grid item xs={9} sm={6}>
                <ImageSubGrid
                  styles={styles}
                  leftImage={leftImage}
                  rightImage={rightImage}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

PageHero.propTypes = {
  focusColor: PT.string,
  textColor: PT.string,
  text: PT.string.isRequired,
  images: PT.arrayOf(PT.object),
};

export default PageHero;
